<template>
   <YsPagination ref="node" :total="total">
      <template #header>
         <el-select v-model="goodstype" placeholder="选择商品类型">
            <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
         </el-select>
         <el-input v-model="name" placeholder="请输入商品名称查找" clearable />
         <el-button type="primary" @click.prevent="query">查询</el-button>
         <el-button type="success" @click="addUser(1)">新增商品</el-button>
      </template>
      <el-table :data="goodlist" height="100%" style="width: 100%" border>
         <el-table-column label="序号" type="index" width="100" />
         <el-table-column label="商品名称" width="180">
            <template #default="scope">
               <el-tag>{{ scope.row.name }}</el-tag>
            </template>
         </el-table-column>
         <el-table-column label="库存" prop="stock">
         </el-table-column>
         <el-table-column label="类型" prop="typeName">
         </el-table-column>
         <el-table-column label="经销商价格" prop="price">
         </el-table-column>
         <el-table-column label="合伙人价格" prop="partnerPrice">
         </el-table-column>
         <!-- <el-table-column label="生产日期" prop="manufactureDate">
         </el-table-column>
         <el-table-column label="保质期" prop="warrantyDate">
         </el-table-column> -->
         <el-table-column label="状态">
            <template #default="scope">
               <el-tag class="ml-2" style="cursor: pointer;" type="success" v-if="scope.row.status == 1"
                  @click="handleStatus(scope.row)">
                  上架</el-tag>
               <el-tag class="ml-2" style="cursor: pointer;" type="info" v-else
                  @click="handleStatus(scope.row)">下架</el-tag>
            </template>
         </el-table-column>
         <el-table-column label="操作" width="400">
            <template #default="scope">
               <el-button size="small" @click="addUser(2, scope.row)" v-if="scope.row.status != 1">
                  编辑</el-button>
               <el-button size="small" type="danger" v-if="scope.row.status != 1"
                  @click="handleDelete(scope.row)">删除</el-button>
               <el-button size="small" type="primary" @click="handleStock(scope.row)">修改库存</el-button>
               <el-button size="small" type="warning" v-if="pageNo != 1 || scope.$index != 0"
                  @click="handleSort(scope.row, 1)">上移</el-button>
               <el-button size="small" type="warning" v-if="scope.$index != goodlist.length - 1"
                  @click="handleSort(scope.row, 2)">下移</el-button>
               <el-button size="small" type="success" v-if="pageNo != 1 || scope.$index != 0"
                  @click="handleSort(scope.row, 3)">置顶</el-button>
            </template>
         </el-table-column>
      </el-table>

   </YsPagination>


   <!-- 用户新增和编辑弹窗 -->
   <el-dialog v-model="dialogFormVisible" :title="type == 1 ? '新增' : '编辑'" center width="70%">
      <el-form :model="addForm" :rules="rules" ref="ruleFormRef" status-icon>
         <el-row>
            <el-col :span="12">
               <el-form-item label="商品名称" :label-width="formLabelWidth" prop="name">
                  <el-input v-model="addForm.name" placeholder="请填写商品名字" autocomplete="off" clearable />
               </el-form-item>
            </el-col>
            <el-col :span="12">
               <el-form-item label="商品类型" :label-width="formLabelWidth" prop="type">
                  <el-select v-model="addForm.type" placeholder="选择商品类型">
                     <el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id" />
                  </el-select>
               </el-form-item>
            </el-col>
            <el-col :span="12"> <el-form-item label="商品库存" :label-width="formLabelWidth" prop="stock">
                  <el-input-number v-model="addForm.stock" :disabled="type == 2" :min="1" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="起定量" :label-width="formLabelWidth" prop="minCount">
                  <el-input-number v-model="addForm.minCount" :min="1" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="经销商价格" :label-width="formLabelWidth" prop="price">
                  <el-input-number v-model="addForm.price" :min="0" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="合伙人价格" :label-width="formLabelWidth" prop="partnerPrice">
                  <el-input-number v-model="addForm.partnerPrice" :min="0" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="优惠价格" :label-width="formLabelWidth" prop="favorablePrice">
                  <el-input-number v-model="addForm.favorablePrice" :min="0" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="净重" :label-width="formLabelWidth" prop="weight">
                  <el-input-number v-model="addForm.weight" :min="0" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="计量单位" :label-width="formLabelWidth" prop="unit">
                  <el-input v-model="addForm.unit" placeholder="请填写计量单位" autocomplete="off" clearable />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="发货地址" :label-width="formLabelWidth" prop="shippingAddress">
                  <el-input v-model="addForm.shippingAddress" placeholder="请填写发货地址" autocomplete="off" clearable />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="包装方式" :label-width="formLabelWidth" prop="packagingMethod">
                  <el-input v-model="addForm.packagingMethod" placeholder="请填写包装方式" autocomplete="off" clearable />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="配料表" :label-width="formLabelWidth" prop="ingredientList">
                  <el-input v-model="addForm.ingredientList" placeholder="请填写配料表" autocomplete="off" clearable />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="保质期" :label-width="formLabelWidth" prop="warrantyDate">
                  <el-input-number v-model="addForm.warrantyDate" :min="0" :max="1000000" />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="保存方式" :label-width="formLabelWidth" prop="storageMethod">
                  <el-input v-model="addForm.storageMethod" placeholder="请填写保存方式" autocomplete="off" clearable />
               </el-form-item></el-col>
            <el-col :span="12"> <el-form-item label="生产日期" :label-width="formLabelWidth" prop="manufactureDate">
                  <el-date-picker v-model="addForm.manufactureDate" type="datetime" format="YYYY-MM-DD HH:mm:ss"
                     placeholder="请填写生产日期" />
               </el-form-item></el-col>
            <el-col :span="24"> <el-form-item label="品牌图片" :label-width="formLabelWidth" prop="brandImage">
                  <div class="img-box" v-if="addForm.brandImage">
                     <el-image style="width: 100px; height: 100px" :src="makeImagePath(addForm.brandImage)" fit="cover" />
                     <div class="tips">
                        <el-icon class="my-tips" @click="deletebrandImage">
                           <Delete />
                        </el-icon>
                     </div>
                  </div>
                  <div class="addimage-box" @click="addImage" v-if="!addForm.brandImage">
                     <el-icon class="my-icon">
                        <Plus />
                     </el-icon>
                  </div>
               </el-form-item></el-col>
            <el-col :span="24"> <el-form-item label="轮播图片" :label-width="formLabelWidth" prop="bannerImage">
               
                  <div class="img-box" v-show="addForm.bannerImage.length > 0" v-for="item, index in addForm.bannerImage"
                     :key="index">
                     <el-image style="width: 100px; height: 100px" :src="makeImagePath(item)" fit="cover" />
                     <div class="tips">
                        <el-icon class="my-tips" @click="deletebannerImage(index)">
                           <Delete />
                        </el-icon>
                     </div>
                  </div>
                  <div class="addimage-box" @click="addbannerImage" v-if="addForm.bannerImage.length < 5">
                     <el-icon class="my-icon">
                        <Plus />
                     </el-icon>
                  </div>
               </el-form-item></el-col>

            <el-col :span="24"> <el-form-item label="详情图片" :label-width="formLabelWidth" prop="detailImage">
                  <div class="img-box" v-show="addForm.detailImage.length > 0" v-for="item, index in addForm.detailImage"
                     :key="index">
                     <el-image style="width: 100px; height: 100px" :src="makeImagePath(item)" fit="cover" />
                     <div class="tips">
                        <el-icon class="my-tips" @click="deletedetailImage(index)">
                           <Delete />
                        </el-icon>
                     </div>
                  </div>
                  <div class="addimage-box" @click="adddetailImage" v-if="addForm.detailImage.length < 5">
                     <el-icon class="my-icon">
                        <Plus />
                     </el-icon>
                  </div>
               </el-form-item></el-col>

         </el-row>

      </el-form>
      <template #footer>
         <span class="dialog-footer">
            <el-button @click="dialogFormVisible = false">关闭</el-button>
            <el-button type="primary" @click="handleSubmit">
               保存
            </el-button>
         </span>
      </template>
   </el-dialog>

   <!-- 修改库存 -->
   <el-dialog v-model="stockdialog" title="修改库存" center>
      <el-form :model="stockForm" :rules="stockrules" ref="stockFormRef" status-icon>
         <el-form-item label="商品库存" :label-width="formLabelWidth" prop="stock">
            <el-input-number v-model="stockForm.stock" :min="0" :max="1000000" clearable />
         </el-form-item>
      </el-form>
      <template #footer>
         <span class="dialog-footer">
            <el-button @click="stockdialog = false">关闭</el-button>
            <el-button type="primary" @click="handlestock">
               保存
            </el-button>
         </span>
      </template>
   </el-dialog>
</template>

<script>
import { goodListApi, goodAddApi, gooddetailApi, goodDeleteApi, goodStatusApi, goodUpdateApi, fileApi, goodstockApi, goodstopApi, goodsortApi } from '@/api/good.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { scaleImage, makeImagePath } from '@/utils/common.js'
import { typesListApi } from '../../api/types'
export default {
   data() {
      return {
         stockForm: {
            stock: 0
         },
         stockrules: {
            stock: [
               { required: true, message: '请输入库存', trigger: 'blur' },
            ],
         },
         stockdialog: false,
         makeImagePath,
         type: 1,
         name: '',
         goodstype:'',
         total: 0,
         goodlist: [],
         dialogFormVisible: false,
         brandImage: '',
         brandImageList: [],
         addForm: {
            name: '',
            type: '', // 类型编码
            price: 0,  // 经销商价格
            partnerPprice: 0,//合伙人价格
            favorablePrice: 0,// 优惠价格
            minCount: 0,
            stock: 0,//库存
            bannerImage: [],//轮播图片 至少两张 530 * 530 
            detailImage: [],//详情图片 至少两张 
            brandImage: '', //品牌图片 330 * 434
            unit: '',//计量单位 
            weight: 0,//净重
            shippingAddress: '',//发货地址
            manufactureDate: '',//生产日期
            packagingMethod: '',//打包方式
            ingredientList: '',//配料表
            warrantyDate: '',//保质期
            storageMethod: '',//保存方式
         },
         formLabelWidth: '140px',
         rules: {
            name: [
               { required: true, message: '请输入商品名称', trigger: 'blur' },
            ],
            type: [
               { required: true, message: '请选择商品类型', trigger: 'blur' },
            ],
            minCount: [
               { required: true, message: '请输入最小下单数量', trigger: 'blur' },
            ],
            stock: [
               { required: true, message: '请输入库存', trigger: 'blur' },
            ],
            price: [
               { required: true, message: '请输入经销商价格', trigger: 'blur' },
            ],
            partnerPrice: [
               { required: true, message: '请输入合伙人价格', trigger: 'blur' },
            ],
            favorablePrice: [
               { required: true, message: '请输入优惠价格', trigger: 'blur' },
            ],
            unit: [
               { required: true, message: '请输入计量单位', trigger: 'blur' },
            ],//计量单位 
            weight: [
               { required: true, message: '请输入净重', trigger: 'blur' },
            ],//净重
            shippingAddress: [
               { required: true, message: '请输入发货地址', trigger: 'blur' },
            ],//发货地址
            manufactureDate: [
               { required: true, message: '请选择生产日期', trigger: 'blur' },
            ],//生产日期
            packagingMethod: [
               { required: true, message: '请输入打包方式', trigger: 'blur' },
            ],//打包方式
            ingredientList: [
               { required: true, message: '请输入配料表', trigger: 'blur' },
            ],//配料表
            warrantyDate: [
               { required: true, message: '请输入保质期', trigger: 'change' },
            ],//保质期
            storageMethod: [
               { required: true, message: '请输入保存方式', trigger: 'blur' },
            ],//保存方式

         },

         /** 类型数据 */
         typeList: []
      }
   },

   created() {
      typesListApi().then(d => {
         if (d.meta?.code === 0) {
            console.log(d)
            this.typeList = d.data
         }
      })
   },

   methods: {
      async handleSort(row, type) {
         console.log(row)
         if (type == 3) { // 置顶
            let res = await goodstopApi({ goodsId: row.id })
            if (res.meta.code == 0) {
               this.$refs.node.queryFunc()
            }

         } else if (type == 2) {// 下移
            let res = await goodsortApi({ goodsId: row.id, type: 'down' })
            if (res.meta.code == 0) {
               this.$refs.node.queryFunc()
            }

         } else if (type == 1) {  // 上移
            let res = await goodsortApi({ goodsId: row.id, type: 'up' })
            if (res.meta.code == 0) {
               this.$refs.node.queryFunc()
            }
         }
      },
      handleStock(row) {

         this.stockForm.id = row.id
         this.stockForm.stock = row.stock
         this.stockdialog = true
      },
      // 修改库存
      handlestock() {
         this.$refs.stockFormRef.validate(async (valid, fields) => {
            if (valid) {
               let res = await goodstockApi(this.stockForm)
               if (res.meta.code == 0) {
                  this.stockdialog = false
                  this.$refs.node.queryFunc()
                  ElMessage({
                     type: 'success',
                     message: `修改库存成功！`,
                  })
               }

            } else {
               console.log('error submit!', fields)
            }
         })
      },
      // 删除
      deletedetailImage(index) {
         this.addForm.detailImage.splice(index, 1)
      },
      // 添加轮播图图片
      async adddetailImage() {
         let arr = []
         arr[0] = await scaleImage()
         let res = await fileApi({ file: arr })
         if (res.meta.code == 0) {
            this.addForm.detailImage.push(res.data[0].fileId)
         }
      },
      deletebannerImage(index) {
         this.addForm.bannerImage.splice(index, 1)
      },
      // 添加轮播图图片
      async addbannerImage() {
         let arr = []
         // arr[0] = await scaleImage(1060, 868)
         arr[0] = await scaleImage(530, 434)
         let res = await fileApi({ file: arr })
         if (res.meta.code == 0) {
            this.addForm.bannerImage.push(res.data[0].fileId)
         }
      },
      // 删除品牌图片
      deletebrandImage() {
         this.addForm.brandImage = ''
      },
      // 添加品牌图片
      async addImage() {
         let arr = []

         // let d = await scaleImage(800, 800)
         let d = await scaleImage(400, 400)
         if (!d) {
            return
         }
         arr[0] = d
         let res = await fileApi({ file: arr })
         if (res.meta.code == 0) {
            this.addForm.brandImage = res.data[0].fileId
         }
      },
      query() {
         this.$refs.node.pageNo = 1;
         this.$refs.node.queryFunc()
      },

      // 获取用户列表数据
      async getList(obj) {
         let res = await goodListApi({ ...obj, name: this.name ,type:this.goodstype})
         if (res.meta.code == 0) {
            this.goodlist = res.data.rows
            this.total = res.data.count
         }
      },
      // 显示添加修改商品弹窗
      async addUser(type, data) {
         this.type = type
         if (type == 1) {
            this.addForm = {
               name: '',
               price: 0,  // 经销商价格
               partnerPprice: 0,//合伙人价格
               favorablePrice: 0,// 优惠价格
               stock: 0,//库存
               bannerImage: [],//轮播图片 至少两张 530 * 530 
               detailImage: [],//详情图片 至少两张 
               brandImage: '', //品牌图片 330 * 434
               unit: '',//计量单位 
               weight: 0,//净重
               shippingAddress: '',//发货地址
               manufactureDate: '',//生产日期
               packagingMethod: '',//打包方式
               ingredientList: '',//配料表
               warrantyDate: '',//保质期
               storageMethod: '',//保存方式
            }
            this.dialogFormVisible = true

         } else {
            this.addForm = data
            let res = await gooddetailApi(data.id)
            if (res.meta.code == 0) {
               this.addForm = res.data
               this.dialogFormVisible = true
            }

         }

      },
      // 保存或修改商品信息
      handleSubmit() {
         this.$refs.ruleFormRef.validate(async (valid, fields) => {
            if (valid) {
               if (this.type == 1) {
                  let res = await goodAddApi(this.addForm)
                  if (res.meta.code == 0) {
                     this.$message.success('商品添加成功！')
                     this.dialogFormVisible = false
                     this.$refs.node.queryFunc()
                  }
               } else {
                  let res = await goodUpdateApi(this.addForm)
                  if (res.meta.code == 0) {
                     this.dialogFormVisible = false
                     this.$refs.node.queryFunc()
                     ElMessage({
                        type: 'success',
                        message: `修改成功！`,
                     })
                  }
               }

            } else {
               console.log('error submit!', fields)
            }
         })
      },
      // 修改商品状态
      handleStatus(row) {
         let str = row.status == 1 ? '下架' : "上架"
         ElMessageBox.confirm(
            `你确定要${str}该商品么？`,
            'Warning', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
         }
         )
            .then(async () => {
               let params = {
                  id: row.id,
                  status: row.status == 1 ? 0 : 1
               }
               let res = await goodStatusApi(params)
               if (res.meta.code == 0) {
                  this.$refs.node.queryFunc()
                  ElMessage({
                     type: 'success',
                     message: `${str}成功！`,
                  })
               }
            })
            .catch(() => {
               ElMessage({
                  type: 'info',
                  message: `取消${str}！`,
               })
            })
      },

      handleEdit() { },
      // 删除商品
      handleDelete(row) {
         ElMessageBox.confirm(
            '你确定要删除该商品么？',
            'Warning', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
         }
         )
            .then(async () => {
               let res = await goodDeleteApi(row.id)
               if (res.meta.code == 0) {
                  this.$refs.node.queryFunc()
                  ElMessage({
                     type: 'success',
                     message: '删除成功！',
                  })
               }
            })
            .catch(() => {

            })
      }



   },
}
</script>

<style lang='less' scoped>
.content-box {
   margin: 20px;
   background-color: #ffffff;
}

.addimage-box {
   font-size: 28px;
   color: #8c939d;
   width: 100px;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px dashed #4c4d4f;
   border-radius: 6px;
   cursor: pointer;
   position: relative;
   overflow: hidden;
}

.addimage-box:hover {
   border: 1px dashed #0f54dc;
}

.img-box {
   position: relative;

   >.tips {
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      position: absolute;
      font-size: 24px;
      top: 0;
      left: 0;
      cursor: pointer;
      margin-right: 10px;

      .my-tips {
         display: none;
      }
   }

   .tips:hover {
      background-color: rgba(1, 1, 1, 0.5);

      >.my-tips {
         display: block;
      }
   }
}
</style>
